<template>
  <v-card class="d-flex flex-column fill-height">
    <v-system-bar>Maturaarbeit</v-system-bar>
    <v-list class="fill-height">
      <PersonItem :value="value.student" />
      <v-list-item
        :to="
          canEdit()
            ? { name: 'ThesisTitleEdit', params: { id: value.id } }
            : null
        "
      >
        <v-list-item-avatar></v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title style="white-space: normal">
            {{
              !value.id ? "kein Titel erfasst" : value.title
            }}</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-action v-if="canEdit()">
          <v-icon color="primary">mdi-pen</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider inset />
      <v-subheader inset>Betreuung</v-subheader>
      <PersonItem :value="value.firstCoach" />
      <PersonItem :value="value.secondCoach" />
      <v-divider v-if="value.assessor" inset />
      <v-subheader v-if="value.assessor" inset>Zweitbeurteilung</v-subheader>
      <PersonItemBasic v-if="canRetreat()" :value="value.assessor">
        <v-list-item-action>
          <v-icon color="danger" @click="retreat()">mdi-trash-can</v-icon>
        </v-list-item-action>
      </PersonItemBasic>
      <PersonItem v-else :value="value.assessor" />

      <v-divider inset />
      <v-list-item :three-line="value.presentationRoom != null">
        <v-list-item-avatar
          ><v-icon>mdi-presentation</v-icon></v-list-item-avatar
        >
        <v-list-item-content>
          <v-list-item-subtitle>Präsentation</v-list-item-subtitle>
          <v-list-item-title>
            <Date
              v-if="value.presentationDate"
              :value="value.presentationDate"
              long
            />
            <span
              v-if="
                value.presentationTime && value.presentationTime !== '00:00'
              "
              >, {{ value.presentationTime }}</span
            >
          </v-list-item-title>
          <v-list-item-title v-if="value.presentationRoom">{{
            value.presentationRoom.description
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import Date from "common/components/DateValue.vue";
import PersonItem from "common/components/PersonItem.vue";
import PersonItemBasic from "common/components/PersonItemBasic.vue";
export default defineComponent({
  components: { Date, PersonItem, PersonItemBasic },
  props: ["value", "settings"],
  data() {
    return {
      saving: false,
    };
  },
  methods: {
    canEdit() {
      if (!this.settings) {
        return false;
      }
      if (this.$_hasRole("student")) {
        if (!this.settings.studentNow) {
          return false;
        }
        if (!this.settings.titleNow && !this.settings.createNow) {
          return false;
        }
        return (
          this.value.id == 0 ||
          (this.value.student && this.$_isPerson(this.value.student))
        );
      } else {
        if (!this.settings.titleNow) {
          return false;
        }
        if (this.$_isPerson(this.value.firstCoach)) {
          return true;
        }
        if (this.$_isPerson(this.value.secondCoach)) {
          return true;
        }
      }
      return false;
    },
    canRetreat() {
      if (!this.settings) {
        return false;
      }
      return this.settings.assessorNow && this.$_isPerson(this.value.assessor);
    },
    async retreat() {
      if (
        await this.$root.confirm({
          message: "Möchtest du dich von dieser Zweitbeurteilung zurückziehen?",
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        this.saving = true;

        await this.apiPatch({
          resource: "thesis/thesis",
          id: this.value.id,
          key: "assessor",
          value: null,
        });
        this.saving = false;
        this.$emit("changed");
      }
    },
  },
});
</script>
